/* eslint-disable */
<template>
  <v-container class="fntp-container-lucee" id="lucee" fluid tag="section">
    <div style="background-image:url(/icons/png/dragees_home.png);margin-top: -12px;height:750px">
      <div class="shadow_line" style="width:100%">
      </div>

      <v-row style="display:flex;justify-content:center">
        <div class="fntp-pastille-chantier">
          <img style="width:50px;height:50px" src="/icons/png/chantier.png"> 
        </div>
      </v-row>
      <v-row class="gros-text text_bleu" style="display:flex;justify-content:center">
        Rechercher un chantier
      </v-row>
      <v-row class="text-moyen text_gris" style="display:flex;padding: 15px 25% 15px 25%;text-align:center">
        L’ID chantier correspondant au numéro d’identification de votre chantier.
Lors de la création d’un chantier, un numéro d’identification unique (généré automatiquement à partir de l’adresse et du nom de votre chantier) permet à chaque intervenant sur le chantier d’avoir accès aux EEE repérées sur le site.
Entrez le numéro de votre chantier pour y avoir accès et pour télécharger votre rapport qui récapitule toutes les espèces qui ont été identifiées sur l’adresse de l’opération !
      </v-row>
      <v-row style="display:flex;justify-content:center;margin-top:50px">   
        <div class="fntp-input">
          <v-icon size="40">mdi-magnify</v-icon>
          <input id="code_chantier" class="invisible_input" @change="get_chantier" ></input>
        </div>
      </v-row>
    </div>

    <div class="plante_divider"/>

    <div style="display:bloc;">
      <div style="display:flex;width:100%;justify-content: center; margin-top: 30px;">
        <div class="block_3logos"><div style="display:flex;width:100%;justify-content: space-around;align-items: center">
          <img src="/icons/png/TP.png" style="width:60px" />
          <img src="/icons/svg/logo-FNTP.svg" style="width:80px" />
          <img src="/icons/png/TPav.png" style="width:120px" />
          <img src="/icons/png/Plantnet.png" style="width:90px" />
        </div></div>
      </div>

      <div style="display:flex;width:100%;justify-content: center; margin-top: 30px;">
       <div class="block_logos">
          <div class="block_financeurs"><div class="text_bleu" style="margin-bottom:10px">Financeurs</div>
            <div style="display:flex;width:100%;justify-content: space-around;align-items: center">
              <img src="/icons/png/BanqueTerr.png" style="width:150px" />
              <img src="/icons/png/Invest.png" style="width:45px" />
            </div>
          </div>
          <div class="block_concours" ><div class="text_bleu" style="margin-bottom:10px">Avec le concours de :</div>
            <div style="display:flex;width:100%;justify-content: space-around;align-items: center">
              <img src="/icons/png/Eiffage.png" style="width:90px" />
              <img src="/icons/png/Vinci.png" style="width:80px" />
              <img src="/icons/png/EquoVivo.png" style="width:80px" />
              <img src="/icons/png/Cheval.png" style="width:90px" />
              <img src="/icons/png/Tersen.png" style="width:60px" />
            </div>
            <div style="display:flex;width:100%;justify-content: space-around;align-items: center">
              <img src="/icons/png/Colas.png" style="width:90px" />
              <img src="/icons/png/Charier.png" style="width:70px" />
              <img src="/icons/svg/axionable.svg" style="width:110px" />
              <img src="/icons/png/Ylly.png" style="width:90px" />
            </div>            
          </div>
        </div>
      </div>
    </div>
  
  </v-container>
</template>
<script>


  import detail_chantier from "./mixins/eee_backend";
  import Entreprise from "./Entreprise.vue"
  import axios from "axios";
  import VueHtml2pdf from 'vue-html2pdf'
  import router from "../router";

  export default {
    name: 'LUCEE',
    components: {
      'entreprise' : Entreprise
    },
    mixins: [detail_chantier],
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {

      get_chantier()
      {
        let code = document.getElementById("code_chantier").value;
        router.push('/chantier/'+code);
      }
    },
  }
</script>
